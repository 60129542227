import React, { Component } from 'react'
import Link from 'gatsby-link'
import { getImage } from 'utils/get-image'
import { getLanguageFromPath } from 'utils/routing'
import { StaticQuery, graphql } from 'gatsby'
import { getLanguageIndex } from '../../utils/language';

class PageNav extends Component {

    render() {
        const {
            isMobile,
            swapSection,
            visibleSectionIndex,
            location,
            data,
            colour,
        } = this.props

        const currentPath = location.pathname

        const currentLanguage = getLanguageFromPath(currentPath)

        const allLanguages = data.allContentfulLanguage.edges

        const languageIndex = getLanguageIndex(allLanguages, currentPath)

        return (
            <div className={isMobile ? 'mobile-tabs-container' : 'sidebar'}>
                <ul>
                    <li className={visibleSectionIndex == 0 ? 'active' : null} style={visibleSectionIndex == 0 ? colour: {}}>
                        <a onClick={_ => { swapSection(0) }}>
                            {allLanguages[languageIndex].node.contentPageSection1Label}
                            {!isMobile &&
                                <img src={getImage('right-arrow')} />
                            }
                        </a>
                    </li>
                    <li className={visibleSectionIndex == 1 ? 'active' : null} style={visibleSectionIndex == 1 ? colour: {}}>
                        {isMobile ?
                            <a onClick={_ => { swapSection(1) }}>
                                {allLanguages[languageIndex].node.mobileContentPageSection2Label}
                            </a>
                            :
                            <a onClick={_ => { swapSection(1) }}>
                                {allLanguages[languageIndex].node.contentPageSection2Label}
                                <img src={getImage('right-arrow')} />
                            </a>
                        }

                    </li>
                    <li>
                        <Link to={`/${currentLanguage}/clinic-locations`}>
                            {allLanguages[languageIndex].node.contentPageSection3Label}
                            {!isMobile &&
                                <img src={getImage('right-arrow')} />
                            }
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        query {
            allContentfulLanguage {
                edges {
                    node {
                        node_locale
                        contentPageSection1Label
                        contentPageSection2Label
                        mobileContentPageSection2Label
                        contentPageSection3Label
                    }
                }
            }
        }
        `}
        render={data => (
            <PageNav data={data} {...props} />
        )}
    />
)