import React, { Component } from 'react'
import './contentPage.scss';
import LayoutWrapper from "src/layouts/LayoutWrapper"
import 'react-slidedown/lib/slidedown.css'
import { getImage } from 'utils/get-image'
import PageNav from 'components/PageNav/PageNav'
import Accordion from 'components/Accordion/Accordion'
import HelplineTable from 'components/HelplineTable/HelplineTable'
import MaternityTable from 'components/MaternityTable/MaternityTable'

import { 
    getColour,
    getCurrentSection,
    getLanguageFromPath,
    processJson
} from 'utils'

import Link from 'gatsby-link'

class ContentPage extends Component {
    isMobile = false
    pageSlug = ''
    contentPageContainer = null
    visibleSectionIndex = 0

    state = {
        visibleSectionIndex: 0,
        accordionVisible: 0,
        accordionsOpen: [{ index: 0 }],
        tables: []
    }

    swapTab = (tableIndex, tabIndex) => {
        const tables = [...this.state.tables]

        tables.splice(tableIndex, 1, tabIndex)

        this.setState({
            tables
        })

    }

    swapSection = index => {
        
        this.setState({
            visibleSectionIndex: index
        })

        window.openTabs[this.pageSlug] = index

    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    }

    componentWillMount() {
        const data = this.props.data
        const thisPageData = data.contentfulContentPage
        const thisIntroduction = thisPageData.introduction
        const contentBlocks = thisIntroduction && thisIntroduction.contentBlocks ? thisIntroduction.contentBlocks : null
        const tables = [...this.state.tables]

        contentBlocks && contentBlocks.map((contentBlock, index) => {

            tables.push(0)

            this.setState({
                tables
            })

        })

        this.pageSlug = this.props.pageContext.slug

        if(typeof window != 'undefined') {
            if(window.openTabs) {
                if(window.openTabs[this.pageSlug]) {
                    this.visibleSectionIndex = window.openTabs[this.pageSlug]
                } 

                this.setState({
                    visibleSectionIndex: this.visibleSectionIndex
                })
            } else {
                window.openTabs = {[this.pageSlug]: 0}
            }
        }

    }

    render() {

        if (typeof window != 'undefined') {
            this.isMobile = window.innerWidth <= 800 ? true : false
        }

        const {
            visibleSectionIndex,
        } = this.state

        const {
            location,
            data
        } = this.props

        const currentSection = getCurrentSection(location)

        let thisLanguage = getLanguageFromPath(location.pathname)

        const thisPageData = data.contentfulContentPage
        const resources = thisPageData.resources

        const thisIntroduction = thisPageData.introduction

        const contentBlocks = thisIntroduction && thisIntroduction.contentBlocks ? thisIntroduction.contentBlocks : null

        const currentPath = location.pathname

        const pageColour = getColour(location)

        const subColour = getColour(location, 0.11)

        let mainColourStyle = { background: `${pageColour}` }

        let subColourStyle = { background: `${subColour}` }

        const homeString = this.props.pageContext.home 

        const learnString = this.props.pageContext.learn

        if (!this.isMobile) {
            return (
                <LayoutWrapper>
                    <div className={'content-page-container'} ref={ref => this.contentPageContainer = ref}>
                        <div className={'title-bar padding-x'} style={mainColourStyle}>
                            <h1>
                                {thisPageData.title}
                            </h1>
                        </div>
                        <div className="breadcrumbs">
                            <Link to={'/' + thisLanguage}>
                                {
                                    homeString
                                }
                            </Link>
                            <label>
                                {' / '}
                            </label>
                            <Link to={'/' + thisLanguage + '/' + currentSection}>
                                {this.props.pageContext.parent}
                            </Link>
                            <label>
                                {' / '}
                            </label>
                            <label>
                                {' ' + thisPageData.title}
                            </label>
                        </div>
                        <div className={'content-body padding-x'}>
                            <PageNav
                                isMobile={false}
                                swapSection={this.swapSection}
                                getImage={getImage}
                                visibleSectionIndex={visibleSectionIndex}
                                location={location}
                            />
                            <div className={'body-content'}>
                                {visibleSectionIndex == 1 ?
                                    <div className={'content'}>
                                        {thisPageData.helplineTable ?
                                            <HelplineTable lines={thisPageData.helplineTable} />
                                            : null
                                        }
                                        { processJson(resources) }
                                    </div>
                                    :
                                    <ul className={'content-blocks'}>
                                        {contentBlocks && contentBlocks.map((contentBlock, index) => {
                                            const thisTabIndex = this.state.tables[index]

                                            return (
                                                <li key={index}>
                                                    <div className={'content'}>
                                                        { processJson(contentBlock.content)}
                                                        { contentBlock.youtubeVideoId ?
                                                                <iframe
                                                                    className="youtube-video"
                                                                    width="560" height="360"
                                                                    src={'https://www.youtube.com/embed/' + contentBlock.youtubeVideoId}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                >
                                                                </iframe>
                                                        : null }
                                                        { contentBlock.maternityTable ? 
                                                                <MaternityTable location={location} />
                                                        : null }
                                                    </div>
                                                    {contentBlock.table &&
                                                        <div className={'table'}>
                                                            <ul
                                                                className={'sidebar'}
                                                                style={mainColourStyle}
                                                            >
                                                                {(contentBlock.table.tabs && contentBlock.table.tabs).map((tab, tabIndex) => {
                                                                    return (
                                                                        <li key={tabIndex} className={thisTabIndex == tabIndex ? 'active' : null} >
                                                                            <a
                                                                                onClick={_ => { this.swapTab(index, tabIndex) }}
                                                                                style={thisTabIndex == tabIndex ? subColourStyle : mainColourStyle}
                                                                            >
                                                                                {tab.title}
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            <div className={'content'} style={subColourStyle}>
                                                                { processJson(contentBlock.table.tabs[thisTabIndex].content) }
                                                                {contentBlock.table.tabs[thisTabIndex].links ?
                                                                    <div className={'learn-more'}>
                                                                        <p>
                                                                            { learnString }
                                                                        </p>
                                                                        <ul>
                                                                            { contentBlock.table.tabs[thisTabIndex].links.map((link, linkIndex) => {
                                                                                    return (
                                                                                        <li key={linkIndex}>
                                                                                            <a 
                                                                                                href={link.url} target="_blank"
                                                                                                style={{ color: pageColour }}
                                                                                            >
                                                                                                {link.label}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                            }) }
                                                                        </ul>
                                                                    </div>
                                                                    : null
                                                                }
                                                                { contentBlock.table.tabs[thisTabIndex].youtubeVideoId ?
                                                                    <iframe
                                                                        className="youtube-video"
                                                                        width="560" height="360"
                                                                        src={'https://www.youtube.com/embed/' + contentBlock.table.tabs[thisTabIndex].youtubeVideoId}
                                                                        frameBorder="0"
                                                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                        allowFullScreen
                                                                    />
                                                                : null }
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </LayoutWrapper>
            )
        } else {
            return (
                <LayoutWrapper>
                    <div className="breadcrumbs">
                        <p>
                            {currentPath}
                        </p>
                    </div>
                    <PageNav
                        isMobile={true}
                        swapSection={this.swapSection}
                        getImage={getImage}
                        visibleSectionIndex={visibleSectionIndex}
                        location={location}
                        thisPageData={thisPageData}
                        colour={{borderBottom: `4px solid ${pageColour}`}}
                    />
                    {visibleSectionIndex == 1 ?
                        <div className="resources content">
                            {thisPageData.helplineTable ?
                                <HelplineTable lines={thisPageData.helplineTable} />
                                : null
                            }
                            { processJson(thisPageData.resources) }
                        </div>
                        :
                        <ul className={'content-blocks'}>
                            {contentBlocks.map((contentBlock, contentBlockIndex) => {
                                return (
                                    <li key={contentBlockIndex}>
                                        <div className={'content'}>
                                            { processJson(contentBlock.content) }
                                            {
                                                contentBlock.youtubeVideoId ?
                                                    <iframe
                                                        className="youtube-video"
                                                        width="560" height="360"
                                                        src={'https://www.youtube.com/embed/' + contentBlock.youtubeVideoId}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    >
                                                    </iframe>
                                                    : null
                                            }
                                            {contentBlock.helplineTable ?
                                                <HelplineTable lines={contentBlock.helplineTable} />
                                                : null
                                            }
                                            {
                                                contentBlock.maternityTable == true ? 
                                                    <MaternityTable location={location} />
                                                : null
                                            }
                                        </div>
                                        {contentBlock.table &&
                                            <div className={'table'}>
                                                <Accordion
                                                    tabs={contentBlock.table.tabs}
                                                    getImage={getImage}
                                                    colour={mainColourStyle}
                                                    pageColour={pageColour}
                                                    subColour={subColourStyle}
                                                    learnString={learnString}
                                                />
                                            </div>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    }
                </LayoutWrapper>
            )
        }
    }
}

export default ContentPage;

export const pageQuery = graphql`
  query contentPageQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      title
      introduction {
        title 
        contentBlocks {
          title
          content {
            json
          }
          youtubeVideoId
          maternityTable {
            typeOfCare
            cost
            location
            pregnancyEducation
            supportAtTimeOfBirth
            locationOfBirth
          }
          table {
            tabs {
              title
              content {
                json
              }
              links {
                label 
                url
              }
              youtubeVideoId
            }
          }
        }
      }
      resources {
        json
      }
      helplineTable {
        label 
        phoneNumber
        url
      }
    }
  }
`;
